.react-tagsinput--focused {
  border: 1px solid #40a9ff;
  box-shadow: 0px 0px 2px 0 rgba(25, 144, 255, 0.7);
}

.react-tagsinput-input {
  background: transparent;
  border: none;
  padding: 0;
  top: 0;
  color: #111827;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 24px;
  outline: none;
}

.react-tagsinput-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
