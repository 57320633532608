@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

html,
body,
#root,
.App {
  min-height: 100vh;
  width: 100%;
}

.removeBtnCss {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.gjs-two-color {
  background-color: #223343;
}

.gjs-pn-commands,
.gjs-pn-devices-c,
.gjs-pn-options,
.gjs-pn-panel {
  background-color: #fff;
}

.hover-opacity {
  transition: 0.5s;
}

.gjs-pn-commands,
.gjs-pn-devices-c,
.gjs-pn-options,
.gjs-pn-panel {
  background-color: #fff;
}

.hover-opacity {
  transition: 0.5s;
}

.hover-opacity:hover {
  opacity: 0.7;
}

.gjs-layer-title-c {
  min-height: 30px;
  font-size: 12px;
}

.react-flow__controls-button {
  border-radius: 2px;
}

.react-flow__controls-button > svg {
  fill: #4b5563;
}

.react-flow__edges > g {
  outline: none;
}
